import type {LoaderFunctionArgs} from '@remix-run/node'
import {redirect} from '@remix-run/node'
import {getSession} from '~/utils/session.server'

export async function loader({request}: LoaderFunctionArgs) {
  const session = await getSession(request)
  if (session.getUserID()) {
    const user = await session.getUser()
    return redirect(user?.lastVisitedOrganizationID ? `/orgs/${user.lastVisitedOrganizationID}` : '/orgs')
  }

  return null
}

export default function Page() {
  return null
}
